body{
  overflow-x: hidden;
}
.w-80{
    width: 80%;
}
.w-70{
  width: 70%;
}
  .slider-btn{
    padding: 10px;
    border-radius: 24px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
  }
  .icon-size{
    width: 25px;
  }
  .main-heading {
    text-align: center;
    font-weight: 800;
    font-size: 46px;
}
.main-heading span{
  border-bottom: 5px solid #306db6;
  border-radius: 0 0 10px 10px;
}
.hover-up-animation {
  transition: transform 0.3s ease;
}
.hover-up-animation:hover{
  transform: translateY(-10px);
}
.step-icon{
  width: 75px;
  height: 75px;
}
.sub-heading {
  font-weight: 600;
  font-size: 23px;
}
.bg-main {
  background-color: #306db6 !important;
}
.tech-text-formatting {
  align-items: center; 
}
.bg-gray {
  background-color: #2d3748;
}
.soical-icons {
  color: #fff;
  /* border: 1px solid; */
  border-radius: 50%;
  padding: 5px;
}
.float-right{
  float: right;
}
.main-position-unset{
position: unset !important;
}
.slick-next:before, .slick-prev:before{
  color: #306db6;
}
.slick-arrow {
    z-index: 1;
    border-radius: 50%;
    border: 1px solid #fff;
}
.projects img{
  box-shadow: -1px 0px 6px 2px #000;
  border-radius: 10px;
}
  @media only screen and (min-width: 600px) {
    .projects img{
      height: 900px;
      object-fit: fill;
    }
    .carousel-caption{
        position: absolute !important;
        top: 20% !important;
        left: 15% !important;
        padding-top: 1.25rem !important;
        padding-bottom: 1.25rem !important;
        color: #fff !important;
        text-align: left;
      }
      .carousel-caption h1{
        font-size: 61px !important;
          font-weight: bold !important;
      }
      .carousel-caption p{
        font-size: 24px !important;
      }
  }
  @media only screen and (max-width: 600px) {
    .slider-btn{
    font-weight: normal;
    font-size: 12px;
    }
    .slider-btn img{
      height: 20px;
      width:  20px;
    }
    .slick-arrow{
      display: none !important;
    }
    .step-icon{
      height: 50px;
      width: 50px;
    }
    .soical-icons{
      border: none !important;
    }
    .col-sm-6{
      width: 50% !important;
    }
    .slider-mb-img{
        width: 120%;
    }
    .carousel-caption h1{
        font-size: 20px !important;
    }
    .carousel-caption p{
        font-size: 13px !important;
    }
    .slider-btn {
      padding: 2px 2px 4px;
    }
    .main-heading{
      font-size: 27px;
    }
  }
